
import { Pipe, PipeTransform, LOCALE_ID, Inject } from "@angular/core";
import { DatePipe } from "@angular/common";

@Pipe({
  name: "pipeCustomDateFormat",
  standalone: true,
})

export class CustomDateFormatPipe implements PipeTransform {
  private datePipe: DatePipe;
  constructor(@Inject(LOCALE_ID) locale: string) {
    this.datePipe = new DatePipe(locale);
  }

  transform(value: any): string | null | undefined {
    if (value instanceof Date) {
      return value.toDateString();
    }
    return this.datePipe.transform(value, "MMM dd, yyyy 'at' hh:mm a");
  }
}