import { CommonModule, NgFor } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import {
  ActivatedRoute,
  Router,
  NavigationEnd,
  RouterModule,
} from "@angular/router";
import { filter } from "rxjs/operators";
import { Input } from "@angular/core";

@Component({
  selector: "app-bread-crumbs",
  standalone: true,
  imports: [RouterModule, CommonModule, NgFor],
  templateUrl: "./bread-crumbs.component.html",
  styleUrl: "./bread-crumbs.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadCrumbsComponent {
  @Input()
  public deliminator: string = ">";

  crumbFormat: { label: string; url: string } | undefined;
  breadcrumbs: Array<{ label: string; url: string }> | undefined;

  constructor(private router: Router, private route: ActivatedRoute, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        this.breadcrumbs = [];
        let currentRoute: ActivatedRoute | null = this.route.root,
          url = "";
        do {
          const childrenRoutes = currentRoute.children;
          currentRoute = null;
          childrenRoutes.forEach((route) => {
            if (route.outlet === "primary") {
              const routeSnapshot = route.snapshot;

              url +=
                "/" +
                routeSnapshot.url.map((segment) => segment.path).join("/");
              this.breadcrumbs?.push({
                label: route.snapshot.data["breadCrum"] || "",
                url: url,
              });

              currentRoute = route;
            }
          });
          console.log(JSON.stringify(this.breadcrumbs));
          this.cdr.detectChanges();
        } while (currentRoute);
      });
  }
}
