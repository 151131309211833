import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, signal } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppRoutes } from '../../models/route-model';
import { MatIconModule } from '@angular/material/icon';
import { UserLoginInDto } from '../../../submodules/silverlight-common/lib/ts/src/dtos/user.login.dto';
import { AuthService } from '../../services/auth.service';
import { catchError, of } from 'rxjs';
import { HttpClientModule } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { HeaderPanelComponent } from '../header-panel/header-panel.component';

/**
 * Component for displaying the login page.
 */
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, MatIconModule, HttpClientModule, FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
   HeaderPanelComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AuthService]
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup = new FormGroup({});

  constructor(private router: Router, private authService: AuthService) {
    this.router = router; 
    if (this.authService.getCurrentLoginStatus()) { 
      console.log('User already logged info' +  JSON.stringify(this.authService.getCurrentLoggedUserTokenValue()));
      this.router.navigate([AppRoutes.route_home]);
    }
  }
  hide = signal(true);
  errorMessage = '';
  toggleViewPassword = () => {
    this.hide.set(!this.hide());
  }

  ngOnInit() {
    this.loginForm = new FormGroup({
      ctrlUsernameOremail: new FormControl('', [Validators.required]),
      ctrlPass: new FormControl('', Validators.required)
    });
  }

  onSubmit() {
    this.errorMessage = '';
    const loginData: UserLoginInDto = {
      usernameOremail: this.loginForm.get('ctrlUsernameOremail')?.value,
      pass: this.loginForm.get('ctrlPass')?.value
    };

    this.authService.login(loginData).pipe(
      catchError(error => {
        console.error('Login failed', error);
        // Handle the error here, e.g., show an error message to the user
        this.loginForm.setErrors({ loginFailed: true });
        return of(null); // Return a safe value or an empty observable
      })
    ).subscribe(
      (response: any) => {
        if (response) {
          console.log('Login successful', response);
          this.router.navigate([AppRoutes.route_home]);
        }
      }
    );
  }
}
