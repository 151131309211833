//Enum for the display values of user and log types

export enum ReadableDisplayEnum {
  userName = 'Username',
  // userPassword = '',
  userFirstName = 'First Name',
  userMiddleName = 'Middle Name',
  userLastName = 'Last Name',
  userEmailId = 'Email',
  userCountry = 'Country',
  userRegion = 'Region',
  userOrganizationName = 'Organization Name',
  userAssignedSystem = '',
  userAssignedRole = 'Role',
  userProductTypes = 'Product Type',
  userProductInjectorIDs = 'Injector IDs',
  userTokenExpiryTime = 'Token Expiry Time',
  userLicenseExpirationDateTime = 'License Expiration Date Time',
  userIsActive = 'Active Status',
  userCurrentLoggedIn = '',
  userLastLoginTime = '',
  userLastLoginLocation = '',
  userDeviceInfo = 'Device Info',
  userSourceSystem = 'Source System',
  userCreatedBySystemName = 'Created By System Name',
  userIsDevToolsAccess = '',
  userIsPLCAccess = '',
  userCreatedDateTime = '',
  userCreatedBy = 'Created By',
  userUpdatedDateTime = 'Last Updated',
  userUpdatedBy = 'Updated By',
  // Display only User Fields
  userFullName = 'Full Name',
  //Log Fields--
  logEventDateTime = 'Event Date Time',
  logEventType = 'Event Type',
  logSrcSystem = 'Source System',
  logUserName = 'User Name',
  logMessage = 'Message',
}


// /**
//  * Enum for the display values of user and log types
//  * @readonly
//  *
//  * @example DisplayText.UserFields.userName
//  * @returns 'Username'
//  */

// export namespace DisplayText {
//   /**
//    * Enum for the display values of user fields
//    */
//   export enum UserFields {
//     userName = 'Username',
//     userFirstName = 'First Name',
//     userMiddleName = 'Middle Name',
//     userLastName = 'Last Name',
//     userEmailId = 'Email',
//     userCountry = 'Country',
//     userRegion = 'Region',
//     userOrganizationName = 'Organization Name',
//     userRole = 'Role',
//     userProductTypes = 'Product Type',
//     userProductInjectorIDs = 'Injector IDs',
//     userTokenExpiryTime = 'Token Expiry Time',
//     userLicenseExpirationDateTime = 'License Expiration Date Time',
//     userIsActive = 'Active Status',
//     userCurrentLoggedIn = '', // Empty string for undefined display text
//     userLastLoginTime = '',   // Empty string for undefined display text
//     userLastLoginLocation = '', // Empty string for undefined display text
//     userDeviceInfo = 'Device Info',
//     userSourceSystem = 'Source System',
//     userTag = 'Tag',
//     userCreatedBySystemName = 'Created By System Name',
//     userIsDevToolsAccess = '', // Empty string for undefined display text
//     userIsPLCAccess = '', // Empty string for undefined display text
//     userCreatedDateTime = '', // Empty string for undefined display text
//     userCreatedBy = 'Created By',
//     userUpdatedDateTime = 'Last Updated',
//     userUpdatedBy = 'Updated By',
//     userFullName = 'Full Name',
//   }

//   /**
//    * Enum for the display values of log fields
//    */
//   export enum LogFields {
//     logEventDateTime = 'Event Date Time',
//     logEventType = 'Event Type',
//     logSrcSystem = 'Source System',
//     logUserName = 'User Name',
//     logMessage = 'Message',
//   }
// }