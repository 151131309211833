<div class="container">
    <div class="row">
        <div class="xs-12 md-6 mx-auto">
            <div id="countUp">
                <div class="number" data-count="404">404</div>
                <div class="text">Page not found</div>
                <div class="text">Looks like the page you were looking for is no longer here..</div>
            </div>
        </div>
    </div>
</div>            
            