import { Routes } from "@angular/router";
import { NotFoundComponent } from "./not-found/not-found.component";
import { HomeComponent } from "./home/home.component";
import { LoginComponent } from "./login/login.component";
import { UserListComponent } from "./user-list/user-list.component";
//import { UserAddEditComponent } from "./user-add-edit/user-add-edit.component";
import { AppRoutes } from "../models/route-model";
import { ErrorComponent } from "./error/error.component";
import { AuthGuard } from "../helpers/auth.guard";
import { AppUserRole } from "../models/role-model";
import { DevAccessComponent } from "./dev-access/dev-access.component";
import { ActivityViewerComponent } from "./activity-viewer/activity-viewer.component";
import { AddEditUserModalComponent } from "./user-add-edit/user-add-edit.component";

export const routes: Routes = [
  { path: AppRoutes.route_startpage, component: LoginComponent },
  {
    path: AppRoutes.route_home,
    component: HomeComponent,
    data: { breadCrum: "Home" },
    children: [
      {
        path: AppRoutes.route_userlist,
        component: UserListComponent,
        canActivate: [AuthGuard],
        data: { breadCrum: "Users" },
        children: [
          {
            path: AppRoutes.route_useraddedit,
            component: AddEditUserModalComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: AppRoutes.route_activityviewer,
        component: ActivityViewerComponent,
        canActivate: [AuthGuard],
        data: { breadCrum: "Activity Viewer" },
      },
    ],
  },
  { path: AppRoutes.route_notfound, component: NotFoundComponent },
  { path: AppRoutes.route_errorpage, component: ErrorComponent },
  {
    path: AppRoutes.route_devaccess,
    component: DevAccessComponent,
    canActivate: [AuthGuard],
    data: { roles: AppUserRole.role_DevAccess },
  },
];
