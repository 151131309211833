import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { AppRoutes } from '../models/route-model';


export const AuthGuard: CanActivateFn = (route, state) => {
    const authService = inject(AuthService);
    const router = inject(Router);

    const loggedStatus = Boolean(authService.getCurrentLoginStatus());
    console.log('AuthGuard: ' + JSON.stringify(loggedStatus));
    if (loggedStatus === true) {
        return true;
    }
    router.navigate([AppRoutes.route_startpage]);
    return false;
};
  
