import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { version } from '../../../package.json';
import { Subscription } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { AppRoutes } from '../../models/route-model';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { UnsubscribeOnDestroyAdapter } from '../../subscription/unsubscribe-ondestroy-adapter';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-header-panel',
  standalone: true,
  imports: [MatToolbarModule, CommonModule, MatButtonModule],
  templateUrl: './header-panel.component.html',
  styleUrl: './header-panel.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AuthService]
})
export class HeaderPanelComponent extends UnsubscribeOnDestroyAdapter implements OnInit, OnDestroy {
  private userInfoSubscription: Subscription | undefined;
  datetimeLoggedIn = Date.now();
  currentUserName: string = '';
  version = String(version);

  constructor(private router: Router, private authService: AuthService) {
    super();
    this.router = router;

    this.subs.sink = this.authService.getCurrentLoggedUserValueAsObservable().subscribe(value => {
      this.currentUserName = value?.toString() || '';
    });

  }

  ngOnInit() {
  }

  onLogout() {
    this.authService.logout();
    //this.currentUserName = '';
    this.router.navigate([AppRoutes.route_startpage]);
  }

  override ngOnDestroy() {
    this.subs.unsubscribe();
  }
}


