import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ILogData } from '../../submodules/silverlight-common/lib/ts/src/interface/log.data.interface';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ActViewerService {
  static URL = `${environment.api_base_url}/logdata/PLC`;
  constructor(public http: HttpClient) { }

  getAllLogData(): Observable<ILogData[]> {
    return this.http.get<ILogData[]>(ActViewerService.URL);
  }
}
