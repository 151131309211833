
<app-header-panel></app-header-panel>
<div  class="login-container container-div ">

    <mat-card class="login-card"appearance="outlined">
      <mat-card-header>
        <mat-card-title id="login-title">Welcome.</mat-card-title>
        <mat-card-subtitle>Sign in to continue</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        @if(loginForm.errors?.['loginFailed']){
        <ng-container>
          <div class="error-message">Login failed. Please check your credentials and try again.</div>
        </ng-container>
        }
        <form [formGroup]="loginForm">
          <mat-form-field class="flex-input">
            <mat-label>User Name</mat-label>
            <input
              matInput
              id="usernameOremail"
              type="text"
              formControlName="ctrlUsernameOremail"
            />
          </mat-form-field>
          <mat-form-field class="flex-input">
            <mat-label>Password</mat-label>
            <input
              matInput
              id="pass"
              [type]="hide() ? 'password' : 'text'"
              formControlName="ctrlPass"
            />

            <button
            mat-icon-button
            matSuffix
            (click)="toggleViewPassword()"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide()"
          >
            <mat-icon>{{hide() ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>



          </mat-form-field>
          <button
            mat-raised-button
            color="primary"
            type="submit"
            (click)="onSubmit()"
          >
            SIGN IN
          </button>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
