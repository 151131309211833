
<header>
  <mat-toolbar>
    <mat-toolbar-row>
      <img src="../assets/BayerPasslinkLogo.svg" height="100" width="300" alt="Bayer Logo" class="logo" />
      <p>{{ version }}</p>
      <span class="spacer"></span>
      <div class="header-info-content">

        <p>{{ currentUserName }}</p>
        <div *ngIf="currentUserName != ''">
          <button mat-raised-button appearance="outlined" (click)="onLogout()">Logout</button>
        </div>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
</header>
<hr />