import { ChangeDetectionStrategy, Component } from "@angular/core";
import { NavigationEnd, Router, RouterModule, RouterOutlet } from "@angular/router";
import { BreadCrumbsComponent } from "../bread-crumbs/bread-crumbs.component";
import { CommonModule } from "@angular/common";
import { AppRoutes } from "../../models/route-model";
import { MatCardModule } from "@angular/material/card";
import { MatIconModule } from "@angular/material/icon";
import { HeaderPanelComponent } from "../header-panel/header-panel.component";

/**
 * Component for displaying the home page.
 */
@Component({
  selector: "app-home",
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterOutlet, RouterModule, CommonModule, BreadCrumbsComponent, MatCardModule, MatIconModule, HeaderPanelComponent],
  templateUrl: "./home.component.html",
  styleUrl: "./home.component.scss",
})
export class HomeComponent {
  appRoutes = AppRoutes;
  isChild = false;

  constructor(private router: Router) { 
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if(event.url === '/home') {
          this.isChild = false;
        }
      };
    });
  } 
}
