import { ReadableDisplayEnum } from './../../submodules/silverlight-common/lib/ts/src/common-enums/readable.user.enums';
import { Pipe, PipeTransform } from '@angular/core';
//import { ReadableDisplay } from '../models/readable-display';
@Pipe({
  name: 'readableDisplay',
  standalone: true,
})
export class ReadableDisplayPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): string {
    const displayName = ReadableDisplayEnum[value as keyof typeof ReadableDisplayEnum]//todo put common enums from common module // ReadableDisplay[value as keyof typeof ReadableDisplay];
    if (displayName) {
      return displayName;
    } else {
      return value;
    }
  }
}
