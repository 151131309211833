import {
  IUserProfile,
  locationData,
} from './../../../submodules/silverlight-common/lib/ts/src/interface/user.profile.interface';
import { MatChipsModule } from '@angular/material/chips';
import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  inject,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatStepperModule } from '@angular/material/stepper';
import {
  FormBuilder,
  Validators,
  FormsModule,
  ReactiveFormsModule,
  FormGroup,
  FormControl,
  FormArray,
} from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MatIconModule } from '@angular/material/icon';
import {
  MatDatepickerModule,
} from '@angular/material/datepicker';
import { provideNativeDateAdapter } from '@angular/material/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { JsonPipe, KeyValuePipe } from '@angular/common';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { UserService } from '../../services/user.service';
import { CountryListService } from '../../services/country-list.service';
import { ICountryList } from '../../../submodules/silverlight-common/lib/ts/src/interface/country.list.interface';
import { ReadableDisplayEnum } from '../../../submodules/silverlight-common/lib/ts/src/common-enums/readable.user.enums';
import { plmUserRoleEnum } from '../../../submodules/silverlight-common/lib/ts/src/common-enums/user.role.enums';
import { configSystemEnum } from '../../../submodules/silverlight-common/lib/ts/src/common-enums/config.system.enums';
import { CreateUserProfileDto } from '../../../submodules/silverlight-common/lib/ts/src/dtos/create-user.profile.dto';
import { UnsubscribeOnDestroyAdapter } from '../../subscription/unsubscribe-ondestroy-adapter';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-add-edit-user-modal',
  standalone: true,
  imports: [
    JsonPipe,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatChipsModule,
    MatIconModule,
    MatDatepickerModule,
    MatCheckboxModule,
    KeyValuePipe,
    MatSlideToggleModule,
  ],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true },
    },
    provideNativeDateAdapter(),
  ],
  templateUrl: './user-add-edit.component.html',
  styleUrl: './user-add-edit.component.scss',
})
export class AddEditUserModalComponent  extends UnsubscribeOnDestroyAdapter  implements OnInit {
  readonly addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  readonly announcer = inject(LiveAnnouncer);
  countryRegionApiData!: ICountryList[];

  constructor(
    private cdr: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    public modalRef: MatDialogRef<AddEditUserModalComponent, MatInputModule>,
    @Inject(MAT_DIALOG_DATA) public data: {user: IUserProfile,formType:string},
    private userService: UserService,
    private countryListService: CountryListService,
    private authService: AuthService
  ) {
    super();
    this.subs.sink = this.authService.getCurrentLoggedUserValueAsObservable().subscribe(value => {
      this.currentUserName = value?.toString() || '';
    });
  }
  override ngOnDestroy() {
    this.subs.unsubscribe();
  }


  get regions() {
    if (!this.countryRegionApiData || this.countryRegionApiData.length === 0) {
      console.log('No countryRegionApiData');
      return {}; // Return an empty object if countryRegionApiData is not set
    }
    console.log('countryRegionApiData: found');
    return this.countryRegionApiData.reduce((acc: any, current: any) => {
      const { country, region_HQ } = current;

      if (!acc[region_HQ]) {
        acc[region_HQ] = [];
      }

      acc[region_HQ].push(country);
      return acc;
    }, {});
  }
  userInfoFormGroup!: FormGroup;
  userProductInfoFormGroup!: FormGroup;

  ngOnInit(): void {
    this.userInfoFormGroup = this.formBuilder.group({
      userAssignedSystem: 'PLM',
      userAssignedRole: [''],
      userName: [
        '',
        [
          Validators.required,  
          Validators.minLength(4),
          Validators.maxLength(16),
        ],
      ],
      userFirstName: ['', Validators.required],
      userMiddleName: [''],
      userLastName: ['', Validators.required],
      userEmailId: ['', [Validators.required, Validators.email]],
      userCountry: [''],
      userRegion: [''],
      userOrganizationName: ['Bayer', Validators.required],
      userIsActive: [true, Validators.required],
      //The following I dont have displayed
      userCurrentlyLoggedIn: [''],
      userLastLoginTime: [''],
      userLastLoginLocation: [''],
      userCreatedBySystemName: ['PLC'],
      //
    });
    this.userProductInfoFormGroup = this.formBuilder.group({
      userProductTypes: [[], Validators.required],
      userProductInjectorIDs: this.formBuilder.array([
        this.formBuilder.group({
          injectorType: [''],
          injectorID: [[]],
          userLicenseExpirationDateTime: [''],
        }),
      ]),
    });
    this.subs.sink = this.countryListService.getCountryList().subscribe((data) => {
      console.log(data);
      this.countryRegionApiData = data?.CountryListData;
      if (this.data.formType !== 'add') this.populateCurrentUserCountryRegion(this.data.user);
    });
    if (this.data.formType !== 'add') {
      this.userInfoFormGroup.patchValue(this.data.user);
      const currentUserType = this.data.user.userAssignedRole;
      if (currentUserType === 'BIOMED') {
        this.populateProductForm(this.data.user.userProductInjectorIDs);
      }
      this.userProductInfoFormGroup.patchValue(this.data.user);
      // this.cdr.detectChanges();
    }
    this.onFormChanges();
  }

  populateCurrentUserCountryRegion(user: IUserProfile) {
    const userRegion = user.userLocationinfo
      .map((location) => location.region)
      .flat();
    const userCountry = user.userLocationinfo
      .map((location) => location.countries)
      .flat();
    console.log({ userRegion, userCountry });
    console.log(this.countryRegionApiData);
    this.userInfoFormGroup.patchValue({
      userRegion,
      userCountry,
    });
  }
  resetInjectorIDsField() {
    this.injectorIDs.clear();
    this.userProductInfoFormGroup.get('userProductTypes')?.reset();
    this.addProduct();
  }
  populateProductForm(data: any) {
    console.log('Populating product form with:', data);
    // Clear previous product details
    this.injectorIDs.clear();
    // Populate form with new data
    data.forEach((item: any) => {
      const productGroup = this.formBuilder.group({
        injectorType: [item.injectorType],
        injectorID: item.injectorID.map((id: string) => new FormControl(id)),
        userLicenseExpirationDateTime: new FormControl(
          new Date(item.userLicenseExpirationDateTime)
        ),
      });
      this.injectorIDs.push(productGroup);
    });
  }
  private onFormChanges(): void {
   this.userInfoFormGroup
      .get('userAssignedRole')
      ?.valueChanges.subscribe((role) => {
        this.productSpecificDisable();
      });

    this.userInfoFormGroup
      .get('userRegion')
      ?.valueChanges.subscribe((region) => {
        this.productSpecificDisable();
      });
  }
  productSpecificDisable(): void {
    const role = this.userInfoFormGroup.get('userAssignedRole')?.value;
    const region = this.userInfoFormGroup.get('userRegion')?.value;
    if (role === 'FSE' && !region?.length) {
      this.userInfoFormGroup?.get('userCountry')?.reset();
      this.userInfoFormGroup?.get('userCountry')?.disable();
    } else {
      this.userInfoFormGroup?.get('userCountry')?.enable();
    }
  }
  get isSubmitDisabled(): boolean {
    return (
      this.userInfoFormGroup.invalid || this.userProductInfoFormGroup.invalid
    );
  }

  get regionsArray() {
    return Object.keys(this.regions);
  }

  get countriesArray() {
    if (this.userInfoFormGroup.value?.userRegion) {
      return this.userInfoFormGroup.value?.userRegion
        .map((region: string) => this.regions[region])
        .flat();
    }
    return Array.from(new Set(Object.values(this.regions).flat()));
  }
  ReadableDisplay = ReadableDisplayEnum; // This should be data to display the nice user friendly user data fields
  userAssignedRoles:plmUserRoleEnum[] = Object.values(plmUserRoleEnum);
  productTypes = ['Stellant', 'Flex', 'MRXP']; //todo should come from config DB table
  //tokenExpiryTimes = ['8h']; // to remove
  sourceSystem: configSystemEnum[] = Object.values(configSystemEnum);
  currentUserName: string = '';



  onNoClick(): void {
    this.modalRef.close();
  }
  // //CHIPS LOGIC
  get injectorIDs(): FormArray {
    return this.userProductInfoFormGroup.get(
      'userProductInjectorIDs'
    ) as FormArray;
  }
  onProductTypeChange(selectedProductTypes: string[]) {
    selectedProductTypes.forEach((productType) => {
      const productGroup = this.formBuilder.group({
        injectorType: [productType],
        injectorID: this.formBuilder.control([]),
        userLicenseExpirationDateTime: this.formBuilder.control(''),
      });
      console.log('Adding product group:', productGroup);
      this.injectorIDs.push(productGroup);
    });
  }
  get availableProductTypes(): string[] {
    const selectedTypes = this.injectorIDs.value.map(
      (product: any) => product.injectorType
    );
    return this.productTypes.filter((type) => !selectedTypes.includes(type));
  }
  // onBIOMEDProductTypeChange(event: any, index: number) {
  //   const selectedType = event;
  //   console.log('Selected type:', selectedType);
  //   // Remove the selected type from availableProductTypes to avoid duplicates
  //   this.productTypes = this.productTypes.filter(
  //     (type) => type !== selectedType
  //   );
  //   console.log(this.productTypes);
  // }
  createProductFormGroup(): FormGroup {
    return this.formBuilder.group({
      injectorType: [''],
      injectorID: [[]],
      userLicenseExpirationDateTime: [''],
    });
  }
  addProduct() {
    if (this.availableProductTypes.length > 0) {
      this.injectorIDs.push(this.createProductFormGroup());
    }
  }

  removeProduct(index: number) {
    this.injectorIDs.removeAt(index);
  }

  addId(productIndex: number, event: any): void {
    const input = event.input;
    const value = event.value;
    console.log(this.injectorIDs instanceof FormArray);
    if ((value || '').trim()) {
      const ids = this.injectorIDs.at(productIndex).get('injectorID');
      console.log('IDS', ids);
      // ids.push(this.formBuilder.control(value.trim()));
      const newIds = ids?.value.concat(value.trim());
      ids?.setValue(newIds);
    }

    if (input) {
      input.value = '';
    }
  }
  // onDateChange(productIndex: number, event: MatDatepickerInputEvent<Date>) {
  //   const selectedDate = event.value;
  //   const licenseExpiration = this.injectorIDs
  //     .at(productIndex)
  //     .get('licenseExpiration');
  //   if (selectedDate) {
  //     licenseExpiration?.setValue(selectedDate);
  //   }
  // }

  // onDateInput(event: MatDatepickerInputEvent<Date>) {
  //   const inputDate = event.value;

  //   // Additional logic if needed for manual input
  // }
 
  removeId(productIndex: number, idIndex: number): void {
    this.injectorIDs 
      .at(productIndex)
      .get('injectorID')
      ?.value.splice(idIndex, 1);
  }
  getLocationInfo(
    selectedCountries: string[],
    selectedRegions: string[]
  ): locationData[] {
    {
      const result = selectedRegions.map((region) => {
        const filteredCountries = this.regions[region].filter(
          (country: string) => selectedCountries.includes(country)
        );

        return {
          region: region,
          countries: filteredCountries.length > 0 ? filteredCountries : [],
        };
      });

      return result.filter((item) => item.countries.length > 0);
    }
  }
  saveUser(): void {
    // Add logic to save user

    //NOTE: injector ids field will always have data in it regardless of user role unless somthing is changed
    console.log(this.data.user);
    const userInfoFormGroupValue = this.userInfoFormGroup.value;
    const userProductInfoFormGroupValue = this.userProductInfoFormGroup.value;
    // console.log(
    //   JSON.stringify({
    //     ...this.userInfoFormGroup.value,
    //     ...this.userProductInfoFormGroup.value,
    //   })
    // );
    console.log(
      this.userInfoFormGroup.errors,
      this.userProductInfoFormGroup.errors
    );
    const userToBesaved: CreateUserProfileDto = {
      userName: userInfoFormGroupValue.userName,
      userFirstName: userInfoFormGroupValue.userFirstName,
      userMiddleName: userInfoFormGroupValue.userMiddleName || '',
      userLastName: userInfoFormGroupValue.userLastName,
      userEmailId: userInfoFormGroupValue.userEmailId,
      userOrganizationName: userInfoFormGroupValue.userOrganizationName,
      userAssignedRole: userInfoFormGroupValue.userAssignedRole,
      userAssignedSystem: configSystemEnum.PLM,
      userLocationinfo:
        userInfoFormGroupValue.userAssignedRole !== plmUserRoleEnum.BIOMED
          ? this.getLocationInfo(
              userInfoFormGroupValue.userCountry,
              userInfoFormGroupValue.userRegion
            )
          : [],
      userProductTypes: userProductInfoFormGroupValue.userProductTypes,
      userProductInjectorIDs:
        userInfoFormGroupValue.userAssignedRole === plmUserRoleEnum.BIOMED
          ? userProductInfoFormGroupValue.userProductInjectorIDs
          : undefined,
      userIsActive: userInfoFormGroupValue.userIsActive,
      //userUpdatedBy: '???', todo set based on create or edit mode
      userCreatedBy:  this.currentUserName,
      userCreatedBySystemName: configSystemEnum.PLC_APP,
      userIsDevToolsAccess: true,
    }
    console.log({ userToBesaved });

    this.subs.sink = this.userService.createUserProfile(userToBesaved).subscribe((data) => {
      console.log(data);
    });
  }
}
