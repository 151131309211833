import { ChangeDetectionStrategy, Component } from '@angular/core';

// This component is provide Dev related Options
// - Target Server
// - Logs

@Component({
  selector: 'app-dev-access',
  templateUrl: './dev-access.component.html',
  styleUrl: './dev-access.component.scss',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DevAccessComponent {

}
