import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, filter } from 'rxjs';
import { UserLoginInDto } from '../../submodules/silverlight-common/lib/ts/src/dtos/user.login.dto';
import { environment } from '../environments/environment';
import { IUserLogin } from '../../submodules/silverlight-common/lib/ts/src/interface/user.login.interface';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private currentLoggedUserNameSubject: BehaviorSubject<String>
  public currentLoggedUserName$: Observable<String>;

  private currentLoggedUserTokenSubject: BehaviorSubject<String>
  public currentLoggedUserToken$: Observable<String>;

  private currentLoginStatusSubject: BehaviorSubject<boolean>
  public currentLoginStatus$: Observable<boolean>;

  static URL = `${environment.api_base_url}/auth/login/PLM`;
  constructor(private http: HttpClient) {
    let initialUserName: String = '';
    let initialUserToken: String = '';
    let initialLoginStatus: boolean = false;

    if (typeof window !== 'undefined' && window.sessionStorage) {
      initialUserName = sessionStorage.getItem('loggedUserName')?.toString() || '';
      initialUserToken = sessionStorage.getItem('loggedUserToken')?.toString() || '';
      initialLoginStatus = Boolean(sessionStorage.getItem('loggedStatus'));
    }

    this.currentLoggedUserNameSubject = new BehaviorSubject<String>(initialUserName);
    this.currentLoggedUserName$ = this.currentLoggedUserNameSubject.asObservable();

    this.currentLoggedUserTokenSubject = new BehaviorSubject<String>(initialUserToken);
    this.currentLoggedUserToken$ = this.currentLoggedUserTokenSubject.asObservable();

    this.currentLoginStatusSubject = new BehaviorSubject<boolean>(initialLoginStatus);
    this.currentLoginStatus$ = this.currentLoginStatusSubject.asObservable();
  }

  login(userLogin: UserLoginInDto): Observable<IUserLogin> {
    const loginResponse = this.http.post<IUserLogin>(AuthService.URL, userLogin);
    loginResponse.subscribe({
      next: (data: any) => {
        const encUserInfo: IUserLogin = data['encryptedUserInfo'];
        if (typeof window !== 'undefined' && window.sessionStorage) {
          sessionStorage.setItem('loggedUserToken', encUserInfo.access_token);
          sessionStorage.setItem('loggedUserName', encUserInfo.user_name);
          sessionStorage.setItem('loggedStatus', String(true)); 
        }
        this.currentLoggedUserNameSubject.next(encUserInfo.user_name); 
        this.currentLoggedUserTokenSubject.next(encUserInfo.access_token); 
        this.currentLoginStatusSubject.next(true); 
      }
    });
    return loginResponse;
  }

  public getCurrentLoggedUserNameValue() {
    return this.currentLoggedUserNameSubject.value;
  } 

  public getCurrentLoggedUserTokenValue() {
    return this.currentLoggedUserTokenSubject.value;
  }

  public getCurrentLoginStatus(): boolean {
    return Boolean(this.currentLoginStatusSubject.value);
  }

  public getCurrentLoggedUserValueAsObservable() {
    return this.currentLoggedUserNameSubject.asObservable();
  }

  logout() {  
    sessionStorage.removeItem('loggedUserToken');
    sessionStorage.removeItem('loggedUserName');
    sessionStorage.removeItem('loggedStatus');
    this.currentLoggedUserNameSubject.next('');
    this.currentLoggedUserTokenSubject.next('');
    this.currentLoginStatusSubject.next(false);
  }
}  

