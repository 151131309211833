<span class="json-display-container">
  <h2 mat-dialog-title>{{ data.logId ? "View Log Message" : "View User" }}</h2>
  <button mat-icon-button mat-dialog-close class="close-button">
    <mat-icon>close</mat-icon>
  </button>
  <mat-dialog-content>
    @for(column of columns; track column){
    <div [class]="column === 'logMessage'?'message-scroll':'json-display-row'">
      <h3>{{ column | readableDisplay }}:</h3>
      <p>{{ data[column] }}</p>
    </div>
    }
  </mat-dialog-content>
</span>
