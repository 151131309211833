/**
 * Saurabh: 08-25-2024: A class that hold all suscriptions of current page and unsubscribe/re-suscribe on page change
 * // reference : https://blog.angulartraining.com/how-to-automatically-unsubscribe-your-rxjs-observables-tutorial-2f98b0560298
 */
 import { SubscriptionLike } from 'rxjs';

 /**
  * Subscription sink that holds Observable subscriptions
  * until you call unsubscribe on it in ngOnDestroy.
  */
 export class SubSink {
 
   protected _subs: SubscriptionLike[] = [];
 
   /**
    * Subscription sink that holds Observable subscriptions
    * until you call unsubscribe on it in ngOnDestroy.
    *
    * @example
    * In Angular: We can avoid this on each angular component
    * ```
    *   private subs = new SubSink();
    *   ...
    *   this.subs.sink = observable$.subscribe(
    *   this.subs.add(observable$.subscribe(...));
    *   ...
    *   ngOnDestroy() {
    *     this.subs.unsubscribe();
    *   }
    * ```
    */
   constructor() { }
 
   /**
    * Add subscriptions to the tracked subscriptions
    * @example
    *  this.subs.add(observable$.subscribe(...));
    */
   add(...subscriptions: SubscriptionLike[]) {
     this._subs = this._subs.concat(subscriptions);
   }
 
   /**
    * Assign subscription to this sink to add it to the tracked subscriptions
    * @example
    *  this.subs.sink = observable$.subscribe(...);
    */
   set sink(subscription: SubscriptionLike) {
     this._subs.push(subscription);
   }
 
   /**
    * Unsubscribe to all subscriptions in ngOnDestroy()
    * @example
    *   ngOnDestroy() {
    *     this.subs.unsubscribe();
    *   }
    */
   unsubscribe() {
     this._subs.forEach(sub => {
       if (sub)
         sub.unsubscribe()
     });
     this._subs = [];
   }
 }
 