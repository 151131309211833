
import { PipeTransform, Pipe } from "@angular/core";

@Pipe({
  name: "pipeJson",
  standalone: true,
})
export class JsonPipe implements PipeTransform {
  transform(value: any): string {
    return JSON.stringify(value);
  }
}