import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CreateUserProfileDto } from '../../submodules/silverlight-common/lib/ts/src/dtos/create-user.profile.dto';
import { UpdateUserProfileDto } from '../../submodules/silverlight-common/lib/ts/src/dtos/update-user.profile.dto';
import { environment } from '../environments/environment';
import { IUserProfile } from '../../submodules/silverlight-common/lib/ts/src/interface/user.profile.interface';
import { json } from 'stream/consumers';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  static URL = `${environment.api_base_url}/userProfile/PLC`;
  constructor(public http: HttpClient) {}

  getPLMUserProfiles(): Observable<IUserProfile[]> {
    return this.http.get<IUserProfile[]>(`${UserService.URL}/getPLMUserProfiles`);
  }

 createUserProfile(createUserProfileDto: CreateUserProfileDto): Observable<IUserProfile> {
    console.log('createUserProfile - userservice:  ', JSON.stringify(createUserProfileDto));
    return this.http.post<IUserProfile>(UserService.URL, createUserProfileDto);
  }

  updateUserProfile(userId: string, updateUserProfileDto: UpdateUserProfileDto): Observable<IUserProfile> {
    return this.http.put<IUserProfile>(`${UserService.URL}/${userId}`, updateUserProfileDto);
  }
}
