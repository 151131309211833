import { Router, RouterModule, RouterOutlet } from '@angular/router';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialog } from '@angular/material/dialog';
import { jsonDisplayModalComponent } from './json-display-modal/json-display-modal.component';
import { GridComponent } from '../../helpers/components/grid/grid.component';
import { UserService } from '../../services/user.service';
import { AppRoutes } from '../../models/route-model';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTableDataSource } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { CommonModule, JsonPipe } from '@angular/common';
import { Observable } from 'rxjs';
import { BreadCrumbsComponent } from '../bread-crumbs/bread-crumbs.component';
import { AddEditUserModalComponent } from '../user-add-edit/user-add-edit.component';
import { IUserProfile } from '../../../submodules/silverlight-common/lib/ts/src/interface/user.profile.interface';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrl: './user-list.component.scss',
  standalone: true,
  imports: [
    MatToolbarModule,
    GridComponent,
    MatButtonModule,
    MatIconModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    RouterOutlet,
    RouterModule,
    CommonModule,
    JsonPipe,
    BreadCrumbsComponent,
  ],
  animations: [
    trigger('slideInOut', [
      state(
        'in',
        style({
          transform: 'translateX(0)',
          opacity: 1,
        })
      ),

      state(
        'out',
        style({
          transform: 'translateX(100%)',
          opacity: 0,
        })
      ),
      transition('in => out', animate('300ms ease-in-out')),
      transition('out => in', animate('300ms ease-in-out')),
    ]),
  ],
})
export class UserListComponent implements OnInit {
  filterForm: FormGroup;
  users$: Observable<IUserProfile[]> | undefined;

  constructor(
    private userService: UserService,
    private modal: MatDialog,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private router: Router
  ) {
    this.filterForm = this.fb.group({
      textFilterValue: [''],
      activeStatus: [null],
    });
    this.filterForm.valueChanges.subscribe(() => this.applyFilter());
  }

  dataObject?: Observable<any> | undefined;
  rowDataRef?: MatTableDataSource<any>;

  // display columns // todo
  //   userName: string;
  //   userFullName: string;
  //   userEmailId: string;
  //   userRole: string;
  //   userProductTypes: string[];
  //   userProductInjectorIDs: string[];
  //   userLicenseExpirationDateTime: Date;
  //   userIsActive: boolean;

  ngOnInit(): void {
    // this.users$ = this.userService.getAllUsers();
    this.dataObject = this.userService.getPLMUserProfiles();
    //console.log('this.users$   ' + JSON.stringify(this.users$.forEach(  user => console.log(user))));
  }

  filterVisible = false;
  toggleFilter() {
    this.filterVisible = !this.filterVisible;
  }
  clearFilter() {
    this.filterForm.reset();
    this.applyFilter();
  }
  setRowData(rowData: MatTableDataSource<any>) {
    this.rowDataRef = rowData;
  }
  applyFilter() {
    const textFilterValue = this.filterForm.get('textFilterValue')?.value
      ? this.filterForm.get('textFilterValue')?.value.trim().toLowerCase()
      : '';
    const activeStatus = this.filterForm.get('activeStatus')?.value;

    console.log(textFilterValue, activeStatus, 'textFilterValue');

    if (this.rowDataRef) {
      this.rowDataRef.filterPredicate = (data: any, filter: string) => {
        const [text, status] = filter.split('|');
        const textMatches = JSON.stringify(data).toLowerCase().includes(text);
        const booleanMatches =
          status === 'null' || data.userIsActive.toString() === status;

        return textMatches && booleanMatches;
      };

      const combinedFilter = `${textFilterValue}|${activeStatus}`;
      this.rowDataRef.filter = combinedFilter;
    }
  }
  openAddEditUserModal(): void {
    const modalRef = this.modal.open(AddEditUserModalComponent, {
      width: 'auto',
      data: {formType: 'add'},
    });
  }
  // openBulkUsersModal() {
  //   const modalRef = this.modal.open(AddBulkUserModalComponent, {
  //     width: 'auto',
  //   });
  // }
  openEditUserModal(user: {}): void {
    console.log(user, 'EDIT USER MODAL');
    const modalRef = this.modal.open(AddEditUserModalComponent, {
      width: 'auto',
      data: {user, formType: 'edit'},
    });
  }
  openjsonDisplayModal(user: {}): void {
    const modalRef = this.modal.open(jsonDisplayModalComponent, {
      width: 'auto',
      data: {user, formType: 'view'},
    });
  }
  jsonDisplayActivity(user: any): void {
    console.log(user, 'view user activity');
    this.dataObject?.subscribe((users) => {
      const selectedUser = users?.userprofileData.find(
        ({ userName }: any) => userName === user.userName
      );
      this.router.navigate(['/home/' + AppRoutes.route_activityviewer], {
        state: selectedUser,
      });
    });
  }
}
