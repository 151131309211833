import { ChangeDetectionStrategy, Component } from '@angular/core';

/**
 * Component for displaying error messages.
 */
@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrl: './error.component.scss',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorComponent {

}
