import {
  Component,
  AfterViewInit,
  OnChanges,
  Input,
  Output,
  ViewChild,
  SimpleChanges,
  EventEmitter,
} from '@angular/core';
import { CustomDateFormatPipe } from '../../../pipes/date-format-pipe';
import { ActViewerService } from '../../../services/act-viewer.service';
import { UserService } from '../../../services/user.service';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule, MatPaginator } from '@angular/material/paginator';
import { MatSortModule, MatSort } from '@angular/material/sort';
import { MatTableModule, MatTableDataSource } from '@angular/material/table';
import { ReadableDisplayPipe } from '../../../pipes/readable-display.pipe';
import { JsonPipe } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-grid',
  standalone: true,
  imports: [
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    ReadableDisplayPipe,
    MatSortModule,
    CustomDateFormatPipe,
    JsonPipe,
    MatTooltipModule,
  ],
  templateUrl: './grid.component.html',
  styleUrl: './grid.component.scss',
})
export class GridComponent implements AfterViewInit, OnChanges {
  @Input() dataObject?: any = []; //DisplayUser[] | DisplayLogs[]; // actual user and act view object
  @Input() gridType: string = '';
  @Output() editUserEvent = new EventEmitter<any>();
  @Output() jsonDisplayEvent = new EventEmitter<any>();
  @Output() jsonDisplayLogsEvent = new EventEmitter<any>();
  @Output() rowDataRef = new EventEmitter<any>();
  rowData = new MatTableDataSource<any>([]);
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  constructor(
    private userService: UserService,
    private actViewerService: ActViewerService
  ) {}
  get columnDefs(): string[] {
    if (this.gridType === 'user')
      return [
        'userName',
        'userFullName',
        'userEmailId',
        'userAssignedRole',
        'userProductTypes',
        'userProductInjectorIDs',
        'userIsActive',
      ];
    if (this.gridType === 'log') {
      return [
        'logEventDateTime',
        'logEventType',
        'logSrcSystem',
        'logUserName',
        'logMessage',
      ];
    }
    console.log('COLUMN DEFS LOST');
    return [];
  }
  editUser(user: any) {
    console.log(user, 'edit');
    this.editUserEvent.emit(user);
  }
  jsonDisplay(displaySelection: any) {
    this.jsonDisplayEvent.emit(displaySelection);
  }
  jsonDisplayLogs(user: any) {
    console.log(user, 'logs');
    this.jsonDisplayLogsEvent.emit(user);
  }
  initializeGrid(): void {
    console.log('IN app-grid', this.gridType);
    let displayObject: any;
    this.dataObject.subscribe((data: any) => {
      console.log('DATA', data);
      if (this.gridType === 'user') displayObject = data.userprofileData;
      if (this.gridType === 'log') displayObject = data.logdataData;

      console.log('DO LENGTH', displayObject?.length);
      if (!displayObject) return;
      console.log('IN app-grid', displayObject);
      this.rowData = new MatTableDataSource<any>(displayObject);
      this.paginator.pageSize = 20;
      this.rowData.paginator = this.paginator;
      this.rowData.sort = this.sort;
      this.rowDataRef.emit(this.rowData);
    });
  }
  ngAfterViewInit(): void {
    console.log('after view init', this.dataObject);
    this.initializeGrid();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      console.log(changes);
      console.log('dataObject first changed');
    }
    if (!changes['dataObject'].firstChange) {
      console.log('dataObject changed');
      this.initializeGrid();
    }
  }
}
