import { HttpHandlerFn, HttpInterceptorFn, HttpRequest } from "@angular/common/http";
import { inject } from "@angular/core";
import { AuthService } from "../services/auth.service";

export const AuthInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next:
    HttpHandlerFn) => {
        const authService = inject(AuthService);
        const userTokenValue = authService.getCurrentLoggedUserTokenValue();
        console.log('AuthInterceptor: ' + userTokenValue);

    const modifiedReq = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${userTokenValue}`),
    });
    return next(modifiedReq);
}; 